import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';

import ProductPictureStepper from '../components/ProductPictureStepper.jsx';

function ExpandableProductPictureStepper({ images, productName, openByDefault = false }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(openByDefault);
  const handleImageClick = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => setOpen(false), []);
  const blockClickCascade = React.useCallback((event) => event.stopPropagation(), []);

  return (
    <>
      <ProductPictureStepper
        images={images}
        productName={productName}
        onImageClick={handleImageClick}
        width="50vw"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '0.5em',
            borderStyle: 'solid',
            borderColor: 'divider',
            borderWidth: '1px',
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: theme.palette.background.paper }}>
          <ProductPictureStepper
            images={images}
            productName={productName}
            onImageClick={handleImageClick}
            width="100%"
          >
            <Box display="flex" justifyContent="center" onClick={blockClickCascade}>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="contained"
                startIcon={<KeyboardArrowLeftIcon />}
                sx={{
                  textAlign: 'center',
                  my: 1,
                  width: 'fit-content',
                  borderRadius: '0.25em',
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              >
                BACK
              </Button>
            </Box>
          </ProductPictureStepper>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ExpandableProductPictureStepper;
