import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import DimensionsDisplay from '../components/DimensionsDisplay.jsx';
import ExpandableProductPictureStepper from './ExpandableProductPictureStepper.jsx';
import ProductDialog from './ProductDialog.jsx';
import ProductImageMasonry from './ProductImageMasonry.jsx';

function ProductCard({ product }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const prettyPrice = React.useMemo(() => Number(product.tzPrice).toFixed(2), [product?.tzPrice]);
  return (
    <>
      <Card
        key={`product-card-${product.id}`}
        data-cy="product-card"
        sx={{
          boxSizing: 'border-box',
          height: '100%',
          borderRadius: '0.5rem',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: (theme) => theme.palette.divider,
          mx: { xs: 0, md: 0.5 },
          '&:hover': {
            cursor: 'pointer',
            outlineColor: (theme) => theme.palette.info.main,
            outlineStyle: 'solid',
            outlineWidth: '0.125em',
          },
        }}
        onClick={handleClick}
      >
        <CardContent
          sx={{
            height: '100%',
            boxSizing: 'border-box',
            backgroundColor: (theme) => theme.palette.background.paper,
            py: '0.75em',
            px: '1em',
            mb: 0,
            display: { xs: 'block', md: 'flex' },
          }}
        >
          <Box flexDirection="column" boxSizing="border-box" display="flex">
            <Typography variant="subtitle1" fontWeight="bold">
              {product.name}
            </Typography>
            <Box mt={1} borderRadius="0.5em" boxSizing="border-box">
              <Box display={{ xs: 'block', md: 'none' }} alignItems="center" flexGrow={1}>
                <ExpandableProductPictureStepper
                  images={product.images}
                  productName={product.name}
                />
              </Box>
              <Box display={{ xs: 'none', md: 'block' }} boxSizing="border-box">
                <ProductImageMasonry product={product} />
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1" data-cy="product-part-number">
                <strong>Part Number:</strong> <i>{product.tzPartNumber}</i>
              </Typography>
              <Typography variant="subtitle1" data-cy="product-oem-part-number">
                <strong>Supplier PN:</strong> <i>{product.oemPartNumber}</i>
              </Typography>
              {product.model && (
                <Box data-cy="product-model">
                  <strong>Bed Model:</strong> {product.model}
                </Box>
              )}
              <Typography variant="subtitle1" data-cy="product-foam-type">
                <strong>Foam Type:</strong>
                <Chip size="small" sx={{ ml: 1 }} label={product.foamType} />
              </Typography>
              <Typography variant="subtitle1" data-cy="product-price">
                <strong>Price:</strong> ${prettyPrice}
              </Typography>
              <DimensionsDisplay
                width={product.width}
                length={product.length}
                thickness={product.thickness}
              />
              <Box sx={{ width: '100%' }} display="flex" alignSelf="end" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: '0.5em',
                    width: { xs: '100%', md: '60%' },
                    height: 'auto',
                  }}
                >
                  DETAILS
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <ProductDialog
        key={`product-dialog-${product.id}`}
        product={product}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

export default ProductCard;
